<script>
import { Line } from "vue-chartjs";
import "chartjs-plugin-labels";

export default {
  extends: Line,
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },

  mounted() {
    this.handleRenderChart();
  },
  methods: {
    handleRenderChart() {
      this.renderChart(this.chartdata, this.options);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
